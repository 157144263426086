
import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion/'
import Callbacks from './callbacks'

const withContainer = Component => (props) => {
  const setFormValues = hooks.useFormChange()
  const [
    defaultVehicleOptions,
    defaultDriverOptions,
    imposedVehicleOptions,
    imposedDriverOptions,
    allowedVehicleOptions,
    allowedDriverOptions
  ] = hooks.useFieldValues([
    'default_vehicle_options',
    'default_driver_options',
    'imposed_vehicle_options',
    'imposed_driver_options',
    'allowed_vehicle_options',
    'allowed_driver_options'
  ])

  const onVehicleOptionsChange = useCallback(
    Callbacks.onVehicleOptionsChangeHandler(defaultVehicleOptions, imposedVehicleOptions, allowedVehicleOptions, setFormValues),
    [defaultVehicleOptions, imposedVehicleOptions, allowedVehicleOptions, setFormValues]
  )
  const onDriverOptionsChange = useCallback(
    Callbacks.onDriverOptionsChangeHandler(defaultDriverOptions, imposedDriverOptions, allowedDriverOptions, setFormValues),
    [defaultDriverOptions, imposedDriverOptions, allowedDriverOptions, setFormValues]
  )

  const fixedAllowedVehicleOptions = _.uniq(_.flatten([defaultVehicleOptions, imposedVehicleOptions]))
  const fixedAllowedDriverOptions = _.uniq(_.flatten([defaultDriverOptions, imposedDriverOptions]))
  const fixedDefautlVehicleOptions = imposedVehicleOptions
  const fixedDefautlDriverOptions = imposedDriverOptions

  const mergedProps = {
    onVehicleOptionsChange,
    onDriverOptionsChange,
    fixedDefautlVehicleOptions,
    fixedDefautlDriverOptions,
    fixedAllowedVehicleOptions,
    fixedAllowedDriverOptions
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
