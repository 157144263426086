
class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        name: _.get(record, 'name'),
        load_type: _.get(record, 'load_type'),
        application: _.get(record, 'application'),
        client_type_icon: _.get(record, 'client_type_icon'),
        enabled: _.get(record, 'enabled'),
        display_supervision: _.get(record, 'display_supervision'),
        display_index: _.get(record, 'display_index'),
        default_vehicle_options: _.map(_.get(record, 'default_vehicle_options'), 'vehicle_option_id'),
        default_driver_options: _.map(_.get(record, 'default_driver_options'), 'user_option_id'),
        imposed_vehicle_options: _.map(_.get(record, 'imposed_vehicle_options'), 'vehicle_option_id'),
        imposed_driver_options: _.map(_.get(record, 'imposed_driver_options'), 'user_option_id'),
        allowed_vehicle_options: _.map(_.get(record, 'allowed_vehicle_options'), 'vehicle_option_id'),
        allowed_driver_options: _.map(_.get(record, 'allowed_driver_options'), 'user_option_id'),
        max_load: _.get(record, 'max_load'),
        removal_period_duration: _.get(record, 'removal_period_duration'),
        delivery_period_duration: _.get(record, 'delivery_period_duration'),
        contract_id: _.get(record, 'contract_id'),
        to_be_grouped: _.get(record, 'to_be_grouped'),
        auto_calculate_payment: _.get(record, 'auto_calculate_payment'),
        jobcounter: {
          flat_rate: _.get(record, 'jobcounter.flat_rate'),
          km_rate: _.get(record, 'jobcounter.km_rate'),
          pax_add_cost: _.get(record, 'jobcounter.pax_add_cost'),
        },
        name_trkey: _.get(record, 'name_trkey'),
        title_trkey: _.get(record, 'title_trkey'),
        summary_trkey: _.get(record, 'summary_trkey'),
      }
    }
  }

  static getEmptyFormHandler(commercialpackage_id) {
    return function getEmptyFormValues() {
      return {
        enabled: false
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta, state) {
      return {
        name: _.get(values, 'name'),
        load_type: _.get(values, 'load_type'),
        application: _.get(values, 'application'),
        client_type_icon: _.get(values, 'client_type_icon'),
        enabled: _.get(values, 'enabled'),
        display_supervision: _.get(values, 'display_supervision'),
        display_index: _.get(values, 'display_index'),
        default_vehicle_options: _.get(values, 'default_vehicle_options'),
        default_driver_options: _.get(values, 'default_driver_options'),
        imposed_vehicle_options: _.get(values, 'imposed_vehicle_options'),
        imposed_driver_options: _.get(values, 'imposed_driver_options'),
        allowed_vehicle_options: _.get(values, 'allowed_vehicle_options'),
        allowed_driver_options: _.get(values, 'allowed_driver_options'),
        max_load: _.get(values, 'max_load'),
        removal_period_duration: _.get(values, 'removal_period_duration'),
        delivery_period_duration: _.get(values, 'delivery_period_duration'),
        contract_id: _.get(values, 'contract_id'),
        to_be_grouped: _.get(values, 'to_be_grouped'),
        auto_calculate_payment: _.get(values, 'auto_calculate_payment'),
        jobcounter: {
          flat_rate: _.get(values, 'jobcounter.flat_rate'),
          km_rate: _.get(values, 'jobcounter.km_rate'),
          pax_add_cost: _.get(values, 'jobcounter.pax_add_cost'),
        },
        name_trkey: _.get(values, 'name_trkey'),
        title_trkey: _.get(values, 'title_trkey'),
        summary_trkey: _.get(values, 'summary_trkey'),
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        title: _.get(record, 'name')
      }
    }
  }
}

export default Callbacks
