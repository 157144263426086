import React, { useCallback } from 'react'
import { ModelForm, hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const { commercial_package_id, } = hooks.useParams()

  const formProps = {
    name: 'commercial_package_form',
    model_name: 'commercialpackage',
    record_id: commercial_package_id,
    related_states: [],
    related_searches: [],
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    populate: [
      'default_vehicle_options',
      'default_driver_options',
      'imposed_vehicle_options',
      'imposed_driver_options',
      'allowed_vehicle_options',
      'allowed_driver_options',
      'jobcounter',
      'name_trkey',
      'title_trkey',
      'summary_trkey'
    ],
    initialState: { isReadOnly: !!commercial_package_id }
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
