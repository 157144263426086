class Callbacks {
  static onVehicleOptionsChangeHandler(defaultVehicleOptions, imposedVehicleOptions, allowedVehicleOptions, setFormValues) {
    return function onVehicleOptionsChange() {
      const new_options = _.uniq(_.flatten([defaultVehicleOptions, imposedVehicleOptions, allowedVehicleOptions]))
      setFormValues([{ field: 'allowed_vehicle_options', value: new_options }])
    }
  }
  static onDriverOptionsChangeHandler(defaultDriverOptions, imposedDriverOptions, allowedVehicleOptions, setFormValues) {
    return function onDriverOptionsChange() {
      const new_options = _.uniq(_.flatten([defaultDriverOptions, imposedDriverOptions, allowedVehicleOptions]))
      setFormValues([{ field: 'allowed_driver_options', value: new_options }])
    }
  }
}

export default Callbacks