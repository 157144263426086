import React, { useCallback } from 'react';

import { ExpandPanelComponent, ExpandPanelContent, ExpandPanelTitle, FormContent, SearchForm, SearchFrame } from '@front/squirtle'
import { hooks, I18n } from '@front/volcanion'
import OrderSearchForm from '@abra/panels/OrderSearchForm';
import OrderSearchCallbacks from '@abra/panels/OrderSearchForm/callbacks'

import { Box, Typography } from '@mui/material'

import Callbacks from './callbacks'

const Title = ({ open }) =>
  <Typography fontSize='1.8vw' color={open ? 'primary' : 'text.label'} letterSpacing={2}>
    {I18n.t('order.search')}
  </Typography>

const SearchOrderForm = () => {
  const [openSearchForm, setOpenSearchForm] = hooks.useGlobalState('open_search_form')
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(OrderSearchCallbacks.formToFilterHandler(), [])
  const getButtonStyleProps = useCallback(() => ({ pb: 3 }), [])

  return (
    <Box sx={{ boxShadow: 6, borderRadius: 1 }}>
      <SearchFrame
        formSearchName={'order_search_billing_action'}
        model_name={'order'}
        sort={['createdAt ASC']}
        search_id='order_validation_list'
        populate={['events']}
        allowCreate={false}
        default_limit={30}
        watch_type={'index'}
      >
        <SearchForm
          isReady={openSearchForm}
          filterToForm={filterToForm}
          formToFilter={formToFilter}
          getButtonStyleProps={getButtonStyleProps}
          disableButton={!openSearchForm}
          submitOnMount
          submitOnEnter={false}
        >
          <FormContent>
            <ExpandPanelComponent open={openSearchForm} toggleOpen={setOpenSearchForm}>
              <ExpandPanelTitle><Title open={open} /></ExpandPanelTitle>
              <ExpandPanelContent><OrderSearchForm mode='billing' /></ExpandPanelContent>
            </ExpandPanelComponent>
          </FormContent>
        </SearchForm>
      </SearchFrame>
    </Box>
  )
}

export default React.memo(SearchOrderForm)
